<!--素材中心页面-->
<template>
  <div id="appMain">
    <PanelHeader></PanelHeader>
    <div class="box">
      <div class="box_content">
        <div class="navigation">
          <div @click="router.go(-1)">模板中心</div>
          <i class="iconfont icon-xiangyoussku"></i>
          <span>模板详情</span>
        </div>
        <div class="infoBox">
          <div class="infoImg">
            <div class="infoImg_img" :style="{ backgroundImage: `url(${infoData.coverimg})` }"></div>
          </div>
          <div class="infoText">
            <div class="title-boxt">
              <span class="vip-container" v-if="infoData.tem_member > 0"><i data-v-8cc2a97d="" class="iconfont icon-vip3 tubiao"></i></span>
              <span class="title">{{infoData.title}}</span>
            </div>
            <div class="copyright">
              <span class="commercial"><i class="iconfont icon-yunxu"></i> 商用无忧</span>  可用于<span class="personal" >个人商用/企业商用</span>
            </div>
            <div class="explain">
              <div class="explain_box">
                <div class="explain_title">栏目名称</div><div class="explain_title">{{infoData.group_name}}</div>
              </div>
              <div class="explain_box">
                <div class="explain_title">栏目尺寸</div><div class="explain_title">{{infoData.width}} * {{infoData.height}}px</div>
              </div>
              <div class="explain_box">
                <div class="explain_title">模板描述</div><div class="explain_content">{{infoData.remark}}</div>
              </div>
            </div>
            <div class="btn-rq">
              <div class="edit-btn-rq">
                <NuxtLink :to="'/designs/id/' + infoData.id"><el-button class="edit-btn" type="primary" >开始编辑</el-button></NuxtLink>
              </div>
              <div class="like-btn" @click="cancelFavorite">
                <i  v-if="infoData.like_state==0" title="收藏" class="iconfont icon-hear tubiao"></i>
                <i  v-else title="取消收藏" class="iconfont icon-hear-full sc tubiao"></i>
              </div>
            </div>
            <div class="infoImg_label">
              <div v-for="item in infoData.tag">
                <NuxtLink :to="{name:'templateCenter',query:{k:item}}">{{item}}</NuxtLink>
              </div>
            </div>
          </div>
        </div>
        <h2>相似推荐</h2>
        <similar-recommendations :similarRecommendations="imgList" :key="imgList" :type="type"></similar-recommendations>
        <footer class="panel-footer">
          <public-footer></public-footer>
        </footer>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PanelHeader from "/layout/components/PanelHeader.vue";
import SimilarRecommendations from "./similarRecommendations.vue";
import {savelikestate,getTemplateDetails} from "../../api/apiconfig/main";
import {getLocal} from "../../utils/local";
import {ElMessage} from "element-plus";
import PublicFooter from "@/src/components/PublicFooter/index";

const router = useRouter()
const route = useRoute()
const imgList = ref([])
const infoData = ref({})
const tid = ref()
const type = ref()
const data = ref([]);
const materialImgInfo =  (async ()=>{
  data.value = route.params
  tid.value = data.value.id
  type.value = data.value.type
  const response = await getTemplateDetails({id:data.value.id})
  if(response.data.length!==0){
    imgList.value = response.data.list
    infoData.value = response.data.data
  }
})
await materialImgInfo()
const typeId = ref(2);
//收藏/取消收藏接口
const cancelFavorite = async ()=>{
  const response  = await savelikestate({tid:tid.value,uid:getLocal('uid'),type:1})
  if(response===1){
    materialImgInfo()
    ElMessage.success('操作成功')
  }else{
    ElMessage.error('操作失败')
  }
}
const type_name = ref('图片');
if(type.value == 1){
  type_name.value = '图片';
}else if(type.value == 2){
  type_name.value = '素材';
}else{
  type_name.value = '字体';
}
await useHead({
  title:infoData.value.color_name+infoData.value.style_name+'风格'+infoData.value.industry_name+'行业的'+infoData.value.scene_name+infoData.value.title+'模板',
  meta:[
    {hid:'keywords',name:'keywords','content':infoData.value.scene_name+'模板,'+infoData.value.group_name+'模板,'+infoData.value.industry_name+'模板,松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片'},
    {hid:'description',name:'description','content':'松鼠快图模板中心提供'+infoData.value.group_name+'的'+infoData.value.scene_name+'适用于'+infoData.value.industry_name+'行业的在线设计图片的多种图片设计模板，能够快速实现满足多种需求的在线图片设计功能。'},
  ]
})

</script>
<style scoped>
.btn-rq{
  display: flex;
  width: 100%;
}
.edit-btn-rq{
  width: 100%;
  max-width: 403px;
}
.edit-btn{
  width: calc(100%);
  height:56px;
}
.like-btn{
  width: 56px;
  height: 56px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.like-btn:hover{
  background: #d8dadf;
}
.vip-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  background: #fcddc5;
  color:#6b3d1e;
  border-radius: 2px;
  margin-top: 5px;
  margin-right: 5px;
}
.title-boxt{
  display: flex;
  position: relative;
}
.sc{
  color:#d71e1e;
}
</style>
<style lang='less' scoped>
#appMain {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 1200px;
  .box{
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    .box_content{
      width: 80%;
      .navigation{
        margin-top: 20px;
        width: 100%;
        height: 40px;
        align-items: center;
        display: flex;
        color: #8693ab;
        .navigation_return{
          color: #8693ab;
          text-decoration: none;
          font-size: 16px;
        }
        .navigation_return:hover{
          color: black;
          cursor: pointer;
          font-size: 16px;
        }
        i{
          font-size: 12px;
          margin: 0 5px;
        }
        span{
          color: black;
          font-size: 16px;
        }
        div{
          cursor: pointer;
        }
      }
      .infoBox{
        width: 100%;
        display: flex;
        //justify-content: space-between;
        .infoImg{
          width: 60%;
          min-width: 50%;
          overflow: hidden;
          border-radius: 4px;
          background: #fff;
          display: flex;
          .infoImg_img{
            position: relative;
            width: 100%;
            height:480px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          }
          .infoImg_img::before{
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url("/public/img/water.svg");
            background-size: contain;
            box-sizing: border-box;
          }
        }
        .infoText{
          border-radius: 5px;
          margin-left: 20px;
          width: 40%;
          height: 100%;
          display: flex;
          flex-direction: column;
          user-select: none;
          background: #fff;
          padding: 20px;

          .copyright{
            height: 22px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #505a71;
            margin: 24px 0;
            .commercial{
              color: #07af4c;
              margin-right: 8px;
            }
            .personal{
              color: #0773fc;
            }
          }
          .explain{
            width: 100%;
            border-radius: 4px;
            margin-bottom: 24px;
            padding: 16px;
            box-sizing: border-box;
            font-size: 14px;
            background-color: #f8f8fb;
            .explain_box{
              width: 100%;
              display: flex;
              margin-bottom: 5px;
              .explain_title{
                height: 22px;
                margin-right: 24px;
                color: #8693ab;
              }
              .explain_content{
                flex: 1;
                line-height: 25px;
                letter-spacing: 1.5px;
              }
            }
          }
          .title{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #1b2337;
            position: relative;
          }
          .btn:hover{
            background-color: rgba(138,61,94,1);
          }
          .infoImg_label{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            div{
              padding: 0 10px;
              height: 32px;
              background: #f1f3f9;
              font-size: 14px;
              line-height: 32px;
              color: #1b2337;
              text-align: center;
              border-radius: 4px;
              margin: 0 12px 12px 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>

