<!--模板中心-->
<template>
  <component :is="deviceComponent" />
</template>
<script setup lang="ts">
import TemplateInfoPc  from "@/src/components/templateInfo/templatesinfoPc.vue";
import TemplateInfoWap from "@/src/components/wap/templateCenter/teminfo.vue";
import { useDevice } from '@/src/utils/useDevice'
const { deviceComponent } = useDevice(TemplateInfoPc, TemplateInfoWap)
</script>
<style scoped>
body{

}
</style>